<script setup>
import { ref, computed, onMounted, nextTick, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { validateForms, mobile, schemaMaker } from '@valid/JoiValidation'
import { getCustomerByMobile } from '@service/CustomerService'

const store = useStore()
const customerMobile = computed({
  get() {
    return store.state.footer.customerMobile
  },
  set(value) {
    store.commit('UPDATE_FORM_ITEMS', {
      field: 'customerMobile',
      value
    })
  }
})

// Handle auto focus
const input = ref(null)
onMounted(() => {
  nextTick(() => {
    input.value.$el.querySelector('input').focus()
    setTimeout(() => window.scrollTo({ top: 400 }), 100)
  })
})

// Handle API call
const btnLoading = ref(false)
const emit = defineEmits(['set-customer-phone'])
const searchNewCustomer = async () => {
  if (btnLoading.value) return
  // Validation
  const formItems = { mobile: customerMobile.value }
  const validMobile = schemaMaker({
    mobile: mobile
  })
  const errors = validateForms(formItems, validMobile)

  // Display error (if any)
  if (errors && errors.length > 0) {
    for (let key of errors) {
      const message = { type: 'error', message: key }
      store.dispatch('addToast', message)
    }
    return
  }

  // API call
  btnLoading.value = true
  try {
    const { data } = await getCustomerByMobile(formItems)
    store.commit('SET_CUSTOMER_ID', data['customer_id'])
    const isNew = data['is_new']
    const isComplete = data['completed']
    if (isNew || !isComplete) {
      emit('set-customer-phone', 'success')
    } else {
      const message = {
        type: 'error',
        message: `"${data['customer_name']}" قبلاً در سیستم ثبت شده است.`
      }
      store.dispatch('addToast', message)
      emit('set-customer-phone', 'error')
    }
    btnLoading.value = false
  } catch (ex) {
    switch (ex.response.status) {
      // Handle low credit
      case 402:
        store.dispatch('profile/handleCreditModal', {
          status: 'open',
          type: 'credit'
        })
        break
      default:
        store.dispatch('addToast', {
          type: 'error',
          message: 'درخواست شما موفقیت‌آمیز نبود.'
        })
    }
    emit('set-customer-phone', 'error')
  }
}
</script>

<template>
  <form
    novalidate
    class="modal-form-wrapper"
    @submit.prevent="searchNewCustomer"
  >
    <div class="footer-modal">
      <h4 class="footer-modal__text">
        ثبت مشتری
      </h4>
    </div>
    <base-input
      input-type="number"
      label="شماره همراه"
      start-left
      v-model="customerMobile"
      ref="input"
      phone-number
    />
    <div class="credit-buttons">
      <button type="submit" class="button button--black">
        <svg
          style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
          viewBox="0 0 512 512"
          class="button__icon"
          v-show="!btnLoading"
        >
          <path
            fill="#c69d4e"
            d="M0.09 512l27.14 0c3.7,-122.99 104.9,-221.89 228.77,-221.89 79.98,0 145.05,-65.08 145.05,-145.06 0,-79.97 -65.07,-145.05 -145.05,-145.05 -79.98,0 -145.05,65.07 -145.05,145.05 0,47.94 23.62,92.43 62.99,119.54l11.39 7.85 -13.07 4.51c-36.72,12.66 -69.82,33.55 -97.28,61.01 -46.71,46.71 -73.14,108.27 -74.89,174.04zm255.91 -249.01c-65.03,0 -117.93,-52.91 -117.93,-117.94 0,-65.03 52.9,-117.93 117.93,-117.93 65.03,0 117.93,52.9 117.93,117.93 0,65.03 -52.9,117.94 -117.93,117.94zm170.73 49.31l-27.12 0 0 86.29 -86.29 0 0 27.12 86.29 0 0 86.29 27.12 0 0 -86.29 86.3 0 0 -27.12 -86.3 0 0 -86.29z"
          />
        </svg>
        <spinner v-if="btnLoading" />
      </button>
    </div>
  </form>
</template>
